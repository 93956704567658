/* Setting View, Vue Component */
<template>
  <v-card v-if="entity && !loading" id="setting-view" class="entity-view">
      <v-card-title>View Setting</v-card-title>

      <v-card-text class="d-flex align-center flex-wrap pb-0">
      <v-row class="align-center pb-5">
        <v-col>

        <v-btn
          color="primary"
          class="me-3"
          :to="{ name: 'setting-edit', params: { id: entity.Id } }"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiPencilOutline }}
          </v-icon>
          <span>Edit</span>
        </v-btn>
        </v-col>
        <v-col class="text-right">
        <v-btn
          color="secondary"
          class="me-3"
          :to="{ name: 'setting-create' }"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          <span>Create Setting</span>
        </v-btn>
        <v-btn
          color="secondary"
          class="me-3"
          :to="{ name: 'setting-list'}"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiViewList }}
          </v-icon>
          <span>Back</span>
        </v-btn>
        </v-col>
      </v-row>
      </v-card-text>

      <v-simple-table>
        <thead>
          <tr>
            <th>Field</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>

      <tr>
        <td>Category</td>
        <td>{{ entity.Category }}</td>
      </tr>

      <tr>
        <td>Name</td>
        <td>{{ entity.Name }}</td>
      </tr>

      <tr>
        <td>Value</td>
        <td>{{ entity.Value }}</td>
      </tr>

      <tr>
        <td>Created</td>
        <td>{{ Display.DateTime(entity.Created) }}</td>
      </tr>

      <tr>
        <td>Updated</td>
        <td>{{ Display.DateTime(entity.Updated) }}</td>
      </tr>

        </tbody>
      </v-simple-table>

  </v-card>
</template>

<script>
import store from '@/store'
import { useRouter } from '@core/utils'
import * as Display from '@/datahelpers/displayTypes'

import {
  mdiPencilOutline,
  mdiPlus,
  mdiViewList,
  mdiClose
} from '@mdi/js'

import { ref, watch, computed } from '@vue/composition-api'

export default {
  setup() {

    const { route, router  } = useRouter();

    const entity = computed(() => store.state.app.Setting);
    const loading = ref(false);

    const fetchData = (id) => {
      store
        .dispatch('app/fetchSetting', {
          id: id
        })
        .then(response => {
          loading.value = false
        })
        .catch(error => {
          console.log(error);
          router.push({ name: 'setting-list'});
        })
    }

    // fetch the user information when params change
    //watch(() => route.value.params.id, (id) => fetchData(id));

    // and call now
    fetchData(route.value.params.id);

    

    return {
      loading,
      entity,
      Display,
      
      icons: {
        mdiPencilOutline,
        mdiPlus,
        mdiViewList,
        mdiClose
      },
    };
  }
}
</script>

<style lang="scss" scoped>
.entity-view::v-deep .v-data-table th:last-child {
  width: 66%;
}
</style>

